import RightArrow from "images/right-arrow.svg"
import MenuArrow from "images/Vector-132.svg"
import { useState } from "react"
import { useFormatter } from "../../helpers/i18n"

export default function MenuHeaderItem({ data }) {
  const { Link } = useFormatter()
  const [showMenuChildren, setShowMenuChildren] = useState(null)

  return (
    <li className={`wzChTCzOBu `}>
      <div
        className="ijgePvHfdq"
        onClick={() => {
          if (showMenuChildren === data?.name) {
            setShowMenuChildren(null)
          } else {
            setShowMenuChildren(data?.name)
          }
        }}
      >
        {data?.link ? (
          data?.isOpenNewTab ? (
            <a href={data?.link} target="_blank"></a>
          ) : (
            <Link className="dvkRvnvuKA" to={`${data?.link}`}>
              {data?.name}
            </Link>
          )
        ) : (
          data?.name
        )}

        {data?.children?.length ? (
          <>
            <img src={MenuArrow} verticalalign={0} className="mVtISIbDJs" />
          </>
        ) : null}
      </div>
      {data?.children?.length ? (
        <div
          className={`FOsxgdKAoW ${
            showMenuChildren === data?.name
              ? "mobile-menu__item--active"
              : "mobile-menu__item--inActive"
          }`}
        >
          <nav className="vPNTAJAHGT">
            {data?.children.map((item, index) => {
              return item?.redirectOutside ? (
                <a
                  key={index}
                  className="mmhezgHPkc"
                  href={item?.link}
                  target="_blank"
                >
                  <span dangerouslySetInnerHTML={{ __html: item?.name }}></span>
                  <img src={RightArrow} className="right-arrow" />
                </a>
              ) : (
                <Link key={index} className="mmhezgHPkc" to={item?.link}>
                  <span dangerouslySetInnerHTML={{ __html: item?.name }}></span>
                  <img src={RightArrow} className="right-arrow" />
                </Link>
              )
            })}
          </nav>
        </div>
      ) : null}
    </li>
  )
}
